























import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'

@Component
export default class BatchRenewalDialog extends Vue {
  @PropSync('visible') syncedVisible: boolean
  @Prop() leaseIds: string[]

  formData = { expireMonths: 3 }
  loading = false

  mounted() {}

  close() {
    this.syncedVisible = false
  }

  cancel() {
    this.close()
  }

  async confirm() {
    const { expireMonths } = this.formData

    this.$confirm(
      `<span>
        您正在批量为<span style="color: var(--color-error)">${this.leaseIds.length}</span>位租客续期
        <span style="color: var(--color-error)">${expireMonths}</span>个月，请确认
      </span>`,
      {
        dangerouslyUseHTMLString: true
      }
    )
      .then(async () => {
        this.loading = true
        const { data } = await this.$api.europa.updateLeases({
          leaseIds: this.leaseIds,
          expireMonths
        })

        this.loading = false

        if (data.code === 0) {
          this.$message({ type: 'success', message: '批量续期操作成功' })
          this.$emit('update')
          this.close()
        }
      })
      .catch(() => {})
  }
}

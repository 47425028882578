

































import { Vue, Component, PropSync, Prop, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class TagDialog extends Vue {
  @PropSync('visible') syncedVisible: boolean
  @Prop() name: string
  @Prop() leaseId: string
  @Prop() expireAt: string

  @Ref() form: ElForm

  formData = {
    expireAt: ''
  }
  loading = false

  pickerOptions = {
    disabledDate: (time: Date) => {
      return this.$moment(this.expireAt).isAfter(time)
    },
    shortcuts: [
      {
        text: '续期3个月',
        onClick: (picker: Vue) => {
          picker.$emit('pick', this.getDateAfterMonths(3))
        }
      },
      {
        text: '续期6个月',
        onClick: (picker: Vue) => {
          picker.$emit('pick', this.getDateAfterMonths(6))
        }
      },
      {
        text: '续期12个月',
        onClick: (picker: Vue) => {
          picker.$emit('pick', this.getDateAfterMonths(12))
        }
      }
    ]
  }

  getDateAfterMonths(months: number) {
    return this.$moment(this.expireAt).add(months, 'month')
  }

  close() {
    this.syncedVisible = false
    this.formData = { expireAt: '' }
  }

  cancel() {
    this.close()
  }

  async confirm() {
    const valid = await this.form.validate()

    if (valid) {
      const { expireAt } = this.formData
      this.$confirm(
        `您正在修改${this.name}的有效期为${this.$moment(expireAt).format('YYYY/MM/DD')}，请确认`
      )
        .then(async () => {
          this.loading = true
          const { data } = await this.$api.europa.updateLease(this.leaseId, {
            expireAt: this.$moment(expireAt).endOf('day')
          })

          this.loading = false

          if (data.code === 0) {
            this.$emit('update')
            this.close()
          }
        })
        .catch(() => {})
    }
  }
}






























































































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'
import Modal from '@/components/common/Modal'
import RenewalDialog from './components/RenewalDialog.vue'
import BatchRenewalDialog from './components/BatchRenewalDialog.vue'
import { ElTable } from 'element-ui/types/table'
import { is, phoneNumber, validate } from '@/utils/helpers'
import { resident } from '@/utils/options'
import _ from 'lodash'

type Person = AnyObj
type FilterData = { searchType: 'name' | 'tel'; searchText: string; active?: boolean }

@Component({ components: { PageTitle, SecurityText } })
export default class ExpiredRenters extends Vue {
  @Ref() table: ElTable

  filterData: FilterData = { searchType: 'name', searchText: '' }
  data = []
  total = 0
  selection: Person[] = []
  pagination = { size: 20, current: 1 }
  loading = false
  searchTypes = resident.searchTypes

  searchRules: Record<string, (v: string) => string | void> = {
    tel: v => {
      if (!validate.phoneNumber(v) && !validate.landlineNumber(v)) return '请输入正确的电话号码'
    }
  }

  renewalDialog = Modal(RenewalDialog)
  batchRenewalDialog = Modal(BatchRenewalDialog)

  get searchPlaceholder() {
    const type = resident.searchTypes.find(({ value }) => value === this.filterData.searchType)
    return type ? '请输入' + type.label : ''
  }

  get params() {
    const { searchType, searchText, active } = this.filterData
    const { current, size } = this.pagination

    return _.pickBy(
      {
        [searchType]:
          searchType === 'tel' && validate.phoneNumber(searchText)
            ? phoneNumber.standardize(searchText)
            : searchText,
        offset: (current - 1) * size,
        limit: size,
        searchExpiringRenter: true,
        active
      },
      is.ava
    )
  }

  @Watch('pagination', { deep: true })
  async watchPagination() {
    await this.fetchData()

    if (this.table) {
      this.table.bodyWrapper.scrollTop = 0
    }
  }

  mounted() {
    this.fetchData()

    this.renewalDialog.on('update', this.fetchData)
    this.batchRenewalDialog.on('update', this.fetchData)
  }

  async fetchData() {
    this.loading = true
    const { data } = await this.$api.europa.getPersons(this.params)

    this.loading = false

    if (data.code === 0) {
      this.data = data.data
      this.total = data.count
    }
  }

  search() {
    const { searchType, searchText } = this.filterData

    if (searchText) {
      const validate = this.searchRules[searchType]
      const message = validate && validate(searchText)

      if (message) {
        return this.$message({ message, type: 'error' })
      }
    }

    this.pagination.current = 1
    this.fetchData()
  }

  handleSelectionChange(selection: Person[]) {
    this.selection = selection
  }

  renewLeases() {
    this.batchRenewalDialog.open({ leaseIds: this.selection.map(({ leaseId }) => leaseId) })
  }

  viewPerson({ personId }: AnyObj) {
    this.$router.push({ name: '住户信息查看', params: { id: personId } })
  }

  async renewLease({ name, leaseId, expireAt }: AnyObj) {
    this.renewalDialog.open({ name, leaseId, expireAt })
  }

  async ignoreLease({ leaseId }: AnyObj) {
    this.$confirm('忽略后不再显示该条到期提醒，请确认')
      .then(async () => {
        const { data } = await this.$api.europa.updateLeases({ leaseIds: [leaseId], ignore: true })

        if (data.code === 0) {
          this.$message({ type: 'success', message: '忽略成功' })
          this.fetchData()
        }
      })
      .catch(() => {})
  }
}
